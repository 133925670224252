import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
//import zhLocale from '@smallwei/avue/lib/locale/lang/zh'

import axios, { AxiosRequestConfig } from 'axios'
axios.interceptors.request.use((config: AxiosRequestConfig) => {    // 这里的config包含每次请求的内容
  const token: string = store.getters.token  // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJPcGVuSWQiOiI4ZDA4Y2MwMGM4NWY0M2JlOWMzOWI1NjgyOGI5NzRiNCIsImFwcGlkIjoiOWQxNTA2Yzg5YWM3OTFjNCIsImV4cCI6MTYzNDYxMzIyMn0.g9It1ov56bfUi5fxCiXvx7IT2t-yADw3GuQaUdysaEA"//window.sessionStorage.getItem("token")
  console.info(token)
  config.headers!.Authorization = 'Bearer ' + `${token}`
  return config;
}, function (err) {
  return Promise.reject(err);
})
// import ElementPlusLoading from "./components/elementPlus/elementPlusLoading";
// import ElementPlusMessage from "./components/elementPlus/elementPlusMessage";
// import ElementPlusNotify from "./components/elementPlus/elementPlusNotify";

// import axios from 'axios'
// import moment from 'moment'

// import { APIClient } from './assets/Api2.js'

const app = createApp(App)

// app.config.globalProperties.$moment = moment;
// app.config.globalProperties.axios = Request.axios

// app.config.globalProperties.$Api = APIClient;

// app.config.globalProperties.$http = axios.create({
//   baseURL: '/api2',
// })

app.use(ElementPlus, {
  locale: zhCn,
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(Avue, { axios }) //, { zhLocale }

// app.use(ElementPlusLoading).use(ElementPlusMessage).use(ElementPlusNotify)

app.use(store).use(router).mount('#app')
