import { createStore } from 'vuex'

export type State = {
  nickname: string,
  token: string,
  loginForm: string,
  menu: string,
  regInfo: string,
  roleInfo: string,
  role: string,
}

export default createStore({
  state: {
    nickname: "",
    token: "",
    loginForm: "",
    menu: "",
    regInfo: "",
    roleInfo: "0000000000",
    role: ""
  },
  getters: {
    nickname: (state: State) => {
      const nickname = sessionStorage.getItem("Nickname");
      console.log(nickname);
      if (nickname) {
        return nickname
      }
      else {
        return state.nickname;
      }
    },
    roleInfo: (state: State) => {
      const roleInfo = sessionStorage.getItem("RoleInfo");
      console.log(roleInfo);
      if (roleInfo) {
        return roleInfo
      }
      else {
        return state.roleInfo;
      }
    },
    role: (state: State) => {
      const role = sessionStorage.getItem("Role");
      console.log(role);
      if (role) {
        return role
      }
      else {
        return state.role;
      }
    },
    token: (state: State) => {
      const token = sessionStorage.getItem("Token");
      console.log(token);
      if (token) {
        return token
      }
      else {
        return state.token;
      }
    },
    loginForm: (state: State) => {
      const loginForm = localStorage.getItem("LoginForm");
      console.log(loginForm);
      if (loginForm) {
        return loginForm
      }
      else {
        return state.loginForm;
      }
    },
    menu: (state: State) => {
      const menu = sessionStorage.getItem("Menu");
      console.log(menu);
      if (menu) {
        return menu
      }
      else {
        return state.menu;
      }
    },
    allHospital: (state: State) => {
      const regInfo = sessionStorage.getItem("RegInfo");
      console.log(regInfo);
      if (regInfo) {
        return regInfo
      }
      else {
        return state.regInfo;
      }
    },
    localHospital: (state: State) => {
      const regInfo = sessionStorage.getItem("RegInfo");
      console.log(regInfo);
      if (regInfo) {
        const localHosptital = JSON.parse(regInfo)
        localHosptital.forEach((p: any) => {
          p.children.forEach((c: any) => {
            c.children = c.children.filter((h: any) => {
              h.value += ""
              return h.Docking == false
            })
          })
        })
        return JSON.stringify(localHosptital)
      }
      else {
        return state.regInfo;
      }
    },
    dockingHospital: (state: State) => {
      const regInfo = sessionStorage.getItem("RegInfo");
      console.log(regInfo);
      if (regInfo) {
        const dockingHospital = JSON.parse(regInfo).filter((p: any) => {
          return p.children.filter((c: any) => {
            return c.children.filter((h: any) => {
              h.value += ""
              return h.Docking == true;
            })
          })
        })
        return JSON.stringify(dockingHospital)
      }
      else {
        return state.regInfo;
      }
    },
  },
  actions: {
    SetNickname(content: any, nickname: string) {
      content.commit('SetNickname', nickname)
    },
    SetRoleInfo(content: any, roleInfo: string) {
      content.commit('SetRoleInfo', roleInfo)
    },
    SetRole(content: any, role: string) {
      content.commit('SetRole', role)
    },
    SetToken(content: any, token: string) {
      content.commit('SetToken', token)
    },
    SetLoginForm(content: any, loginForm: string) {
      content.commit('SetLoginForm', loginForm)
    },
    SetMenu(content: any, menu: string) {
      content.commit('SetMenu', menu)
    },
    RemoveLoginForm(content: any) {
      content.commit('RemoveLoginForm')
    },
    RemoveConfig(content: any) {
      content.commit('RemoveConfig')
    },
    SetRegInfo(content: any, regInfo: string) {
      content.commit('SetRegInfo', regInfo)
    },
  },
  mutations: {
    SetNickname(state: State, nickname: string) {
      state.nickname = nickname
      window.sessionStorage.setItem("Nickname", nickname)
    },
    SetRoleInfo(state: State, roleInfo: string) {
      state.roleInfo = roleInfo
      window.sessionStorage.setItem("RoleInfo", roleInfo)
    },
    SetRole(state: State, role: string) {
      state.role = role
      window.sessionStorage.setItem("Role", role)
    },
    SetToken(state: State, token: string) {
      state.token = token
      window.sessionStorage.setItem("Token", token)
    },
    SetLoginForm(state: State, loginForm: string) {
      state.loginForm = loginForm
      window.localStorage.setItem("LoginForm", loginForm)
    },
    SetMenu(state: State, menu: string) {
      state.menu = menu
      window.sessionStorage.setItem("Menu", menu)
    },
    RemoveLoginForm(state: State) {
      state.loginForm = ""
      window.sessionStorage.removeItem("LoginForm")
    },
    RemoveConfig(state: State) {
      state.menu = ""
      state.regInfo = ""
      window.sessionStorage.removeItem("Menu")
      window.sessionStorage.removeItem("RegInfo")
    },
    RemoveMenu(state: State) {
      state.menu = ""
      window.sessionStorage.removeItem("Menu")
    },
    SetRegInfo(state: State, regInfo: string) {
      state.regInfo = regInfo
      window.sessionStorage.setItem("RegInfo", regInfo)
    },
  }
})
