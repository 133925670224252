<template>
  <router-view />
</template>


<style>
.avue-upload__avatar {
  height: auto !important;
}
html,
body {
  padding: 0;
  margin: 0;
}
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
</style>
