import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: '/login' },
  {
    path: '/login', component: () => import('@/views/Login.vue'),
    meta: {
      title: '登录',
    }
  },
  {
    path: '/main', component: () => import('@/views/Main.vue'),
    children: [
      //首页工作小组件
      {
        path: '/work', component: () => import('@/views/Work.vue'),
        meta: {
          title: '工作台',
        }
      },
      //套餐管理
      {
        path: "/config/packages", component: () => import('@/components/config/Packages.vue'),
        meta: {
          title: '套餐管理'
        }
      },
      //菜单设置
      {
        path: "/config/menu", component: () => import('@/components/config/Menu.vue'),
        meta: {
          title: '菜单设置'
        }
      },
      //新后台账号
      {
        path: "/admin/user", component: () => import('@/components/config/User.vue'),
        meta: {
          title: '后台账号'
        }
      },
      //微信公众号菜单
      {
        path: "/admin/wxmenu", component: () => import('@/components/config/WXMenu.vue'),
        meta: {
          title: '微信公众号菜单'
        }
      },
      //短信
      {
        path: "/admin/sms", component: () => import('@/components/admin/SMS.vue'),
        meta: {
          title: '微信公众号菜单'
        }
      },
      //挂号订单按电话统计
      {
        path: "/config/telreport", component: () => import('@/components/register/TelReport.vue'),
        meta: {
          title: '挂号订单按电话统计'
        }
      },
      //老挂号后台账号
      {
        path: "/register/admin", component: () => import('@/components/register/Admin.vue'),
        meta: {
          title: '老挂号后台账号'
        }
      },
      //地市
      {
        path: "/config/city", component: () => import('@/components/config/City.vue'),
        meta: {
          title: '地市管理'
        }
      },
      //坐席账号
      {
        path: "/admin/adminacc", component: () => import('@/components/register/Adminacc.vue'),
        meta: {
          title: '坐席账号'
        }
      },
      //医院信息设置
      {
        path: "/config/hospital", component: () => import('@/components/config/Hospital.vue'),
        meta: {
          title: '医院管理'
        }
      },
      //科室设置
      {
        path: "/config/section", component: () => import('@/components/config/Section.vue'),
        meta: {
          title: '科室管理'
        }
      },
      //医生设置
      {
        path: "/config/doctor", component: () => import('@/components/config/Doctor.vue'),
        meta: {
          title: '医生管理'
        }
      },
      //直连医院医生管理
      {
        path: "/config/doc", component: () => import('@/components/config/Doc.vue'),
        meta: {
          title: '直连医院医生管理'
        }
      },
      //排班设置
      {
        path: "/config/register", component: () => import('@/components/config/Register.vue'),
        meta: {
          title: '排班管理'
        }
      },
      //停诊设置
      {
        path: "/config/stoptreatment", component: () => import('@/components/config/StopTreatment.vue'),
        meta: {
          title: '停诊管理'
        }
      },
      //挂号订单
      {
        path: "/register/order", component: () => import('@/components/register/Order.vue'),
        meta: {
          title: '挂号订单'
        }
      },
      //预约挂号
      {
        path: "/register/register", component: () => import('@/components/register/Register.vue'),
        meta: {
          title: '预约挂号'
        }
      },
      //预约陪诊
      {
        path: "/register/visit", component: () => import('@/components/register/VisitOrder.vue'),
        meta: {
          title: '预约陪诊'
        }
      },
      //专家解读体检报告
      {
        path: "/register/report", component: () => import('@/components/register/ReportOrder.vue'),
        meta: {
          title: '专家解读体检报告'
        }
      },
      //健康咨询
      {
        path: "/register/consult", component: () => import('@/components/register/ConsultOrder.vue'),
        meta: {
          title: '健康咨询'
        }
      },
      //帮助
      {
        path: "/admin/help", component: () => import('@/components/admin/Help.vue'),
        meta: {
          title: '帮助'
        }
      },
      {
        path: "/config/XinJiangUser", component: () => import('@/components/config/XinJiangUser.vue'),
        meta: {
          title: '新疆电信用户'
        }
      },
      {
        path: "/config/XinJiangUserLog", component: () => import('@/components/config/XinJiangUserLog.vue'),
        meta: {
          title: '用户日志'
        }
      },
      //提前预约
      {
        path: "/register/plan", component: () => import('@/components/register/Plan.vue'),
        meta: {
          title: '提前预约'
        }
      },
      //短信报表
      {
        path: "/report/sms", component: () => import('@/components/report/SMS.vue'),
        meta: {
          title: '短信报表'
        }
      },
      //挂号报表
      {
        path: "/report/register", component: () => import('@/components/report/Register.vue'),
        meta: {
          title: '挂号报表'
        }
      },
      //挂号医院报表
      {
        path: "/report/hregister", component: () => import('@/components/report/HRegister.vue'),
        meta: {
          title: '挂号医院报表'
        }
      },
      //操作人报表
      {
        path: "/report/sregister", component: () => import('@/components/report/SRegister.vue'),
        meta: {
          title: '操作人报表'
        }
      },
      //互联网营销安徽区黑名单
      {
        path: "/ah/blacklist", component: () => import('@/components/IM/AHBlackList.vue'),
        meta: {
          title: '安徽营销黑名单'
        }
      },
      //投诉单处理
      {
        path: "/config/TouSu", component: () => import('@/components/config/TouSu.vue'),
        meta: {
          title: '投诉单处理'
        }
      },
      //投诉单列表
      {
        path: "/config/TouSuList", component: () => import('@/components/config/TouSuList.vue'),
        meta: {
          title: '投诉单处理'
        }
      },
      //投诉统计
      {
        path: "/config/TouSuStatistics", component: () => import('@/components/config/TouSuStatistics.vue'),
        meta: {
          title: '投诉单统计'
        }
      },
      //计费节点
      {
        path: "/config/BillingNode", component: () => import('@/components/config/BillingNode.vue'),
        meta: {
          title: '计费节点'
        }
      },
      //收入预算
      {
        path: "/config/RevenueBudget", component: () => import('@/components/config/RevenueBudget.vue'),
        meta: {
          title: '收入预算'
        }
      },
      //兑换权益查询
      {
        path: "/config/quanyi", component: () => import('@/components/config/quanyi.vue'),
        meta: {
          title: '兑换权益查询'
        }
      },
    ]
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  if (typeof (to.meta.title) == "string") {
    document.title = "新疆康庭综合管理平台 " + to.meta.title;
  }
});

export default router
